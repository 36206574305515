import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

var config = {
    apiKey: "AIzaSyDqZvSPYPBFjg6HXK5W9j8-F4RSfXGbUr4",
    authDomain: "free-agent-e901a.firebaseapp.com",
    databaseURL: "https://free-agent-e901a.firebaseio.com",
    projectId: "free-agent-e901a",
    storageBucket: "free-agent-e901a.appspot.com",
    messagingSenderId: "721588814285",
    appId: "1:721588814285:web:7ce8ba0cdb2f903132c484",
    measurementId: "G-686LNMT028"
}

class Firebase {

    constructor() {
        app.initializeApp(config)
        
        this.auth = app.auth()
        this.db = app.firestore()
        this.fns = app.functions()
    }

    createUser = async ({ userInfo, name }) => {
        await this.db.collection('users')
            .doc(userInfo.user.uid)
            .set({
                name,
            })
    }

    searchClaims = async ({ id }) => {
        try {
            const result = await this.fns.httpsCallable('search')(id)
            return result.data
        } catch (error) {
            throw error
        }
    }

    createdClaims = async (authUser) => {
        try {
            const snapshot = await this.db.collection(`users/${authUser.uid}/claims`).get()
            const claims = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            // console.log('claims')
            // // console.log(doc.data())
            // console.log(claims)

            return claims
        } catch (error) {
            throw error
        }
    }

    purchasedClaims = async (authUser) => {
        try {
            const snapshot = await this.db.collection(`users/${authUser.uid}/payments`).get()
            const claims = snapshot.docs.map(doc => ({
                id: doc.id,
                uid: authUser.uid,
                ...doc.data()
            }))

            // console.log('claims')
            // // console.log(doc.data())
            // console.log(claims)

            return claims
        } catch (error) {
            throw error
        }
    }

    createPayment = async ({ claims, email, paymentMethod, onComplete = () => {} }) => {
        // console.log(paymentMethod)
        try {
            if (this.auth.currentUser == null) {
                await this.auth.signInAnonymously()
            }

            const paymentDocRef = await this.db
                .collection(`users/${this.auth.currentUser.uid}/payments`)
                .add({
                    claims: claims.map(claim => claim.id), 
                    email,
                    paymentMethodId: paymentMethod.id,
                    status: 'pending'
                })

            const unsubscribe = paymentDocRef
                .onSnapshot(function(doc) {
                    const data = doc.data()
                    if (data.status === 'success') {
                        unsubscribe()
                        onComplete()
                    }
                })
        } catch (err) {
            // alert("error")
            console.error("Error creating document: ", err)
        }
    }

    // TODO: I really need to cleanup my purchase/payment terminology
    download = async ({ purchase }) => {
        // const ref = await this.db
        // .collection(`users/${this.auth.currentUser.uid}/payments/`)
        // .set({
        //     // claims: claims.map(claim => claim.id), 
        //     // email,
        //     // paymentMethodId: paymentMethod.id,
        //     // status: 'pending'
        // })

        await this.db.
            collection(`users/${this.auth.currentUser.uid}/payments/`)
            .doc(purchase.id)
            .set({
                download: Date(),
            }, { merge: true })


    }

    load = async ({ claimId }) => {
        console.log('claimId', claimId)

        // const ref = await this.db
        // .collection(`users/${this.auth.currentUser.uid}/payments/`)
        // .set({
        //     // claims: claims.map(claim => claim.id), 
        //     // email,
        //     // paymentMethodId: paymentMethod.id,
        //     // status: 'pending'
        // })

            // const claimDoc = await admin
            //     .firestore()
            //     .collection('claims')
            //     .doc(payment.claims[0])
            //     .get()

            // const claim = {
            //     id: claimDoc.id,
            //     ...claimDoc.data()
            // }
            // console.log('claim', claim)

        const claimDoc = await this.db
            .collection('claims')
            .doc(claimId)
            .get()
        // console.log('claim', claim.data())

        return {
            id: claimDoc.id,
            ...claimDoc.data(),
        }

        // await this.db
        //     .collection(`users/${this.auth.currentUser.uid}/payments/`)
        //     .doc(purchase.id)
        //     .set({
        //         download: Date(),
        //     }, { merge: true })
    }

    createContact = async ({ name, email, phoneNumber, message }) => {
        await this.db
            .collection('contacts')
            .add({ name, email, phoneNumber, message })
    }

}

export default Firebase
